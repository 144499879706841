@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@font-face {
  font-family: "IranSans";
  src: url("/src/assets/font/iransans/IRANSansXV.woff2") format("woff2"),
    url("/src/assets/font/iransans/IRANSansXV.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.font-iran {
  font-family: "IranSans", sans-serif;
}
@layer base {
  html {
    font-family: "IranSans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

/* shadows  */
.danger-shadow {
  box-shadow: 0 10px 20px rgba(204, 23, 23, 0.19),
    0 6px 6px rgba(196, 36, 36, 0.23);
}
/* shadows */

/* custom scroll */
.light-scroll::-webkit-scrollbar {
  width: 5px;
  height: 100%;
}

.light-scroll::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

/* chart legend text */

.apexcharts-legend-text {
  color: gray !important;
  margin-right: 5px;
  font-family: "IranSans" !important;
  font-size: 16px !important;
}


.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}




/* Data picker */
.DatePicker{
  z-index: 5 !important;
}
.DatePicker__input{
	background-color: #fff !important;
	border-radius: 10px;
	padding: 10px 5px !important;
	width: 90px;
	border:#8C52FF 1px solid;
  outline: 0;
  position: relative !important;
  z-index: 1 !important;
}
/* 

.Calendar__monthSelector,
.Calendar__yearSelector {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  background-color: #2B2A2A !important;
  transform: translateY(-150%);
  will-change: transform;
  transition: 0.6s;
  height: 100%;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
	background: #8C52FF !important;
	border-radius: 50%;
	color: var(--cl-color-black);
	border-color: transparent;
  }

.Calendar__yearSelectorWrapper::after {
	background-image: linear-gradient(to bottom, #2B2A2A, #2B2A2A 10%, rgba(245, 245, 245, 0)) !important;
	top: -0.1em;
  }
  
  .Calendar__yearSelectorWrapper::before {
	background-image: linear-gradient(to top, #2B2A2A, #2B2A2A 10%, rgba(245, 245, 245, 0)) !important;
	bottom: 0;
  }
  .Calendar__monthYear.-shown > *:hover,
  .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus,
  .Calendar__monthYear > *.-activeBackground {
	background:#8C52FF !important;
  } */

/* Data picker */
